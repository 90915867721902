import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faTelegram } from "@fortawesome/free-brands-svg-icons";
export function Socials() {
  return (
    <Container>
      <a href="https://www.instagram.com/tenaxclub/">
        <FontAwesomeIcon icon={faInstagram} size="2x" />
      </a>
      <a href="https://t.me/s/tenaxclub?q=%23nobodysperfect&before=64">
        <FontAwesomeIcon icon={faTelegram} size="2x" />
      </a>
    </Container>
  );
}
const Container = styled.div`
  z-index: 10;
  display: flex;
  a {
    color: black;
  }
  gap: 15px;
`;

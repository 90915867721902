import React from "react";
import { RepeatedBg } from "./components/repeatedBg";
import { Header } from "./components/header";
import { DownContainer } from "./components/downContainer";
import { Party } from "./components/party";
import { Email } from "./components/email";

import MailchimpSubscribe from "react-mailchimp-subscribe";
import { Welcome } from "./components/welcome";
import ReactGA from "react-ga4";

ReactGA.initialize("G-M6EYP6SE31");

const MailChimpUrl =
  "https://tenax.us10.list-manage.com/subscribe/post?u=fd50f3173d9b528353b73411d&amp;id=f94fae4c59&amp;f_id=003980e3f0";

function App() {
  const [muted, setMuted] = React.useState<boolean>(false);

  return (
    <div className="App">
      <Header isMuted={muted} setMuted={setMuted} />

      <DownContainer>
        <MailchimpSubscribe
          url={MailChimpUrl}
          render={({ subscribe, status, message }) => (
            <>
              {status == null && (
                <>
                  <Party isMuted={muted}></Party>
                  <Email onSubmit={(va) => subscribe(va)} />
                </>
              )}
              {status === "success" && (
                <>
                  <Welcome />
                </>
              )}
            </>
          )}
        />

        <RepeatedBg></RepeatedBg>
      </DownContainer>
    </div>
  );
}

export default App;

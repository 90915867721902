import styled from "styled-components";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm, SubmitHandler } from "react-hook-form";
type Inputs = { EMAIL: string };
export function Email(props: { onSubmit: (values: Inputs) => void }) {
  const {
    register,
    handleSubmit,
    watch,

    formState: { errors },
  } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = (data) => props.onSubmit(data);

  return (
    <form style={{ zIndex: 50 }} onSubmit={handleSubmit(onSubmit)}>
      <SoundCt>
        <WhiteCt>
          <p>INSERT YOUR E-MAIL</p>
          <input type="email" {...register("EMAIL")} required></input>
          <div>
            cliccando invio stai accettando la{" "}
            <a href="https://acconsento.click/informative/htmlpage/5582/it">
              privacy policy
            </a>
          </div>
        </WhiteCt>
        <span>
          <button type="submit">
            <FontAwesomeIcon icon={faPlay} size="2x" color="black" />
          </button>
        </span>
      </SoundCt>
    </form>
  );
}
const SoundCt = styled.div`
  background-color: #d6f94b;
  width: fit-content;
  padding: 5px;
  margin-top: 10px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    padding: 0 5px;
    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
  }
`;
const WhiteCt = styled.div`
  div {
    font-size: 10px;
    color: #c0c0c0;
    font-weight: 100;
    margin-top: 5px;
    a {
      color: #c0c0c0;
    }
  }
  font-weight: 600;
  background-color: white;

  padding: 5px 15px;
  border-radius: 50px;
  input {
    border: none;
    background-color: white;
    width: 180px;
    height: 20px;
    font-family: "Archivo", sans-serif;

    border-bottom: 4px solid black;
    box-sizing: border-box;

    z-index: 50;
    padding: 5px;
    caret-shape: block;
    font-size: 15px;
  }
  p {
    font-size: 10px;
    margin: 0;
  }
`;

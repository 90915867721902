import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeHigh, faVolumeMute } from "@fortawesome/free-solid-svg-icons";

export function Sound(props: {
  isMuted: boolean;
  setMuted: (muted: boolean) => void;
}) {
  return (
    <div onClick={() => props.setMuted(!props.isMuted)}>
      <SoundCt ismuted={props.isMuted}>
        <WhiteCt ismuted={props.isMuted}>
          <span>{!props.isMuted ? "SOUND ON" : "SOUND OFF"}</span>
          <FontAwesomeIcon icon={props.isMuted ? faVolumeMute : faVolumeHigh} />
        </WhiteCt>
      </SoundCt>
    </div>
  );
}
const SoundCt = styled.div<{ ismuted: boolean }>`
  background-color: ${(props) => (props.ismuted ? "#FF0000" : "#d6f94b")};
  width: fit-content;
  padding: 5px;
  margin-top: 10px;
  border-radius: 50px;
  cursor: pointer;
`;
const WhiteCt = styled.div<{ ismuted: boolean }>`
  ${(props) =>
    props.ismuted
      ? css`
          margin-left: 30px;
        `
      : css`
          margin-right: 30px;
        `};
  font-weight: 600;
  font-size: 15px;
  background-color: white;
  width: 120px;
  padding: 6px;

  border-radius: 50px;
  span {
    margin-right: 10px;
  }
`;

import React, { useEffect } from "react";
import styled from "styled-components";

export function Party(props: { isMuted: boolean }) {
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const [hide, setHide] = React.useState(false);
  const playFullScreen = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setHide(true);
    }
  };
  const mute = (val: boolean) => {
    if (videoRef.current) {
      videoRef.current.muted = val;
    }
  };
  const isPlaying = () => {
    if (videoRef.current) {
      return !videoRef.current.paused;
    }
    return false;
  };
  useEffect(() => {
    mute(props.isMuted);
  }, [props.isMuted]);
  return (
    <Container>
      <video
        width="100%"
        height="100%"
        id="myvideo"
        playsInline
        autoPlay
        loop
        ref={videoRef}
      >
        <source src="/video.mp4" type="video/mp4" />
      </video>
      {!hide && (
        <PlayButton onClick={playFullScreen}>
          <img src="/play.webp" />
        </PlayButton>
      )}
    </Container>
  );
}
const Container = styled.div`
  margin-top: 40px;
  width: 100%;
  height: calc(100svh - 300px);

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  max-width: calc(100vw - 50px);

  & > video {
    width: 100%;
    height: 100%;

    object-fit: contain;
  }
  z-index: 10;
  box-sizing: border-box;
`;
const PlayButton = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  img {
    width: 50%;
    height: 50%;
    object-fit: contain;
  }
  cursor: pointer;
`;

import styled from "styled-components";

export function RepeatedBg() {
  return <Bg>{"MEMBERS CLUB > ".repeat(1000)}</Bg>;
}
const Bg = styled.div`
  font-family: "Archivo", sans-serif;
  font-weight: 400;
  line-height: 35px;
  font-size: 35px;
  word-break: break-all;
  position: absolute;
  overflow: hidden;
  height: 100%;
  user-select: none; /* Standard syntax */
`;

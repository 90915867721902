import styled from "styled-components";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function Welcome() {
  return (
    <Container>
      <b>WELCOME TO THE FAMILY</b> <br />
      CHECK YOUR E-MAIL AND COME TO TOAST WITH US WITH US WITH YOUR
      <b>WELCOME FREE-DRINK!</b>
      <br />
      NOW MAKE SURE YOU GET YOUR TICKET FOR THE NEXT EVENT
      <GoToDice />
      <img src="/seeyou.png"></img>
    </Container>
  );
}

const Container = styled.div`
  margin-top: 40px;
  width: 100%;
  height: calc(100svh - 250px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  max-width: calc(100vw - 50px);
  background-color: #d6f94b;
  padding: 1em;
  box-sizing: border-box;

  flex-direction: column;
  z-index: 10;
  font-size: 1.2em;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  b {
    width: 100%;
  }
`;

function GoToDice() {
  return (
    <SoundCt href="https://dice.fm/venue/tenax-3eqv?lng=en">
      <WhiteCt>
        GO TO DICE
        <FontAwesomeIcon icon={faLink} size="2x" />
      </WhiteCt>
    </SoundCt>
  );
}
const SoundCt = styled.a`
  background-color: black;
  width: fit-content;
  padding: 2px;
  margin-top: 10px;
  border-radius: 50px;

  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: black;
`;
const WhiteCt = styled.div`
  font-weight: 600;
  font-size: 15px;
  background-color: white;
  width: fit-content;
  padding: 5px 15px;

  border-radius: 50px;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

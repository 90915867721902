import styled from "styled-components";

export const DownContainer = styled.div`
  height: calc(100svh - 150px);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 70px 90px;
  gap: 5px 0px;
  justify-items: center;
  position: relative;

  font-family: "Archivo", sans-serif;
  z-index: 1;
`;

import styled from "styled-components";
import { Sound } from "./sound";
import { Socials } from "./socialLogos";

export function Header(props: {
  isMuted: boolean;
  setMuted: (muted: boolean) => void;
}) {
  return (
    <Head>
      <div>
        <img src="/tenax-logo.webp"></img> <br />
        JOIN THE FAMILY <br /> AND START LIVING <br />
        EXCLUSIVE EXPERIENCES
        <Sound {...props} />
      </div>
      <Socials />
    </Head>
  );
}

const Head = styled.div`
  font-family: "Archivo", sans-serif;
  padding: 5px;
  img {
    margin-bottom: 1cap;
    width: 150px;
  }
  position: relative;

  display: flex;
  justify-content: space-between;
`;
